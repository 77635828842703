<template lang="pug">
.h-dvh.flex-centered(v-if="loading")
  icon-loading.loading.size-16.text-smBlue.animate-spin
router-view(v-else)
</template>

<script lang="ts" setup>
// external
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import TgWebApp from '@twa-dev/sdk';
import type { Platforms } from '@twa-dev/types';
// internal
import IconLoading from '@/assets/icons/progress-circle.svg';
import appRoutes from '@/router/appRoutes';
import useUserStore from '@/stores/user';
import useSkipIntrosForDev from '@/composables/useSkipIntrosForDev';
import connectAnalytics from '@/utils/analytics';

const router = useRouter();
const userStore = useUserStore();
useSkipIntrosForDev();

const loading = ref<boolean>(true);

async function init() {
  const supportedPlatforms: Platforms[] = ['android', 'android_x', 'ios'];
  if (!supportedPlatforms.includes(TgWebApp.platform)) {
    loading.value = false;
    router.replace(appRoutes.NOT_SUPPORTED_PLATFORM);
    return;
  }

  await userStore.init();

  // Show onboarding for new users
  if (!userStore.data.isOnboardingDone) {
    loading.value = false;
    router.replace(appRoutes.ONBOARDING);
  }

  TgWebApp.disableVerticalSwipes();

  connectAnalytics();

  loading.value = false;
}
init();

onMounted(TgWebApp.expand);
</script>

<style lang="scss" scoped>
</style>
