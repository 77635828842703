// external
import axios from 'axios';
import type { AxiosResponse } from 'axios';
// internal
import apiUser from '@/api/user';
import apiGeneration from '@/api/generation';
import apiPayment from '@/api/payment';
import apiRatings from '@/api/ratings';
import apiQuests from '@/api/quests';
import apiStickers from '@/api/stickers';
import apiSwipes from '@/api/swipes';
import apiFeed from '@/api/feed';
import useUserStore from '@/stores/user';
import useNotification from '@/composables/useNotifications';
import appRoutes from '@/router/appRoutes';
import type { RefreshTokenResponse } from '@/types';

export interface UseApi {
  apiUser: ReturnType<typeof apiUser>,
  apiGeneration: ReturnType<typeof apiGeneration>,
  apiPayment: ReturnType<typeof apiPayment>,
  apiRatings: ReturnType<typeof apiRatings>,
  apiQuests: ReturnType<typeof apiQuests>,
  apiStickers: ReturnType<typeof apiStickers>,
  apiSwipes: ReturnType<typeof apiSwipes>,
  apiFeed: ReturnType<typeof apiFeed>,

  apiErrorCatcher: (error: any) => void,
}

export default function useApi(): UseApi {
  const userStore = useUserStore();
  const notify = useNotification();

  const api = axios.create({
    baseURL: import.meta.env.VITE_API_DOMAIN + import.meta.env.VITE_API_PREFIX,
    withCredentials: true,
  });

  const apiUserInst = apiUser(api);

  // Add Auth token to all requests
  api.interceptors.request.use(config => {
    if (userStore.authToken) {
      config.headers.Authorization = `Bearer ${userStore.authToken}`;
    }
    return config;
  });
  // Handle 401 errors
  api.interceptors.response.use(
    response => response,
    error => {
      // Refresh token
      if (
        error?.response?.status === 401 ||
        (error?.response?.status === 403 && error?.response?.data.detail === 'Refresh your tokens and try again.')
      ) {
        if (!userStore.isLogged) {
          return Promise.reject(error);
        }
        if (error?.config?.url && error.config.url === '/v1/auth/refresh-token/') {
          // console.log('REFRESH TOKEN: update token server error');
          userStore.resetUserData();
          window.location.replace(appRoutes.ROOT);
          return Promise.reject(error);
        }

        return apiUserInst.refreshToken({ refreshToken: userStore.refreshToken })
          .then((response: AxiosResponse<RefreshTokenResponse>) => {
            if (!response.data.newJwtToken) {
              return Promise.reject(error);
            }

            userStore.setAuthToken(response.data.newJwtToken);
            userStore.setRefreshToken(response.data.newRefreshToken);
            // console.log('REFRESH TOKEN: updated tokens');

            if (error.config) {
              // console.log('REFRESH TOKEN: resend request');
              return api.request({ ...error.config });
            }
            // console.log('REFRESH TOKEN: some error');
            return Promise.reject(error);
          })
          .catch(() => {
            // console.log('REFRESH TOKEN: catch error');
            userStore.resetUserData();
            window.location.replace(appRoutes.ROOT);
            return Promise.reject(error);
          });
      }

      return Promise.reject(error);
    },
  );

  function apiErrorCatcher(error: any) {
    if (error?.response?.status === 401) {
      return;
    }

    if (error?.response?.data?.detail) {
      notify({ text: error?.response?.data?.detail });
    } else if (error?.message) {
      notify({ text: error.message });
    } else {
      notify();
    }
  }

  return {
    apiUser: apiUserInst,
    apiGeneration: apiGeneration(api),
    apiPayment: apiPayment(api),
    apiRatings: apiRatings(api),
    apiQuests: apiQuests(api),
    apiStickers: apiStickers(api),
    apiSwipes: apiSwipes(api),
    apiFeed: apiFeed(api),

    apiErrorCatcher,
  };
}
