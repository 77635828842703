<template lang="pug">
.top-menu.py-2.px-4.bg-smGrayLight
  .flex.items-center
    //- Profile avatar -//
    profile-avatar
    //- Username and coins count -//
    router-link.me-1.text-smGray(:to="appRoutes.PROFILE")
      span.inline-block.max-w-56.truncate(class="xs:max-w-80") {{ userStore.data.username }}
      .flex.gap-2
        .coins-balance.py-px.px-2.bg-smYellow.rounded-lg
          .coins-reward.relative.text-smGray
            span.text-sm {{ memeCoinsCount }}
            = ' '
            span.text-xxs.font-light {{ $t('common.points') }}
            .coins-add-animation(v-if="coinsAddAnimationVisible")
              .coin-icon.is-1
              .coin-icon.is-2
              .coin-icon.is-3
              .coin-icon.is-4
              .coin-icon.is-5
            .coins-remove-animation.py-px.px-2.bg-smYellow.rounded-lg(
              v-if="coinsRemoveAnimationVisible"
              class="-ms-2"
            )
              .coins-reward.text-smGray.whitespace-nowrap
                span.text-sm -{{ coinsRemovingCount }}
                = ' '
                span.text-xxs.font-light {{ $t('common.points') }}
        .swipes-balance.flex.items-center.p-px.ps-1.bg-white.rounded-lg
          icon-swipe.size-5.me-1.text-smBlue
          .swipes-balance__value(:style="{ backgroundSize: `${swipesPercents}% 100%` }")
            span {{ userStore.data.swipes }}
            span.text-xs /
            span {{ swipesLimit }}
    //- Additional navigation -//
    top-nav.shrink-0.ms-auto
</template>

<script lang="ts" setup>
// external
import { ref, computed, watch } from 'vue';
// internal
import ProfileAvatar from '@/components/profile/ProfileAvatar.vue';
import TopNav from '@/layouts/default/TopNav.vue';
import IconSwipe from '@/assets/icons/swipe.svg';
import useUserStore from '@/stores/user';
import appRoutes from '@/router/appRoutes';

const userStore = useUserStore();

// Meme coins animation {
const memeCoins = computed<number>(() => userStore.data.memeCoins);
const coinsAddAnimationVisible = ref<boolean>(false);
const coinsRemoveAnimationVisible = ref<boolean>(false);
const coinsRemovingCount = ref<number>(0);

function startCoinAnimation(newCoinsCount: number, oldCoinsCount: number) {
  if (
    userStore.coinsAnimationInProgress || // for running coins animation just one time
    (newCoinsCount === oldCoinsCount)
  ) {
    return;
  }

  if (newCoinsCount > oldCoinsCount) {
    coinsAddAnimationVisible.value = true;
  } else {
    coinsRemoveAnimationVisible.value = true;
    coinsRemovingCount.value = oldCoinsCount - newCoinsCount;
  }

  setTimeout(() => {
    coinsAddAnimationVisible.value = false;
    coinsRemoveAnimationVisible.value = false;
    coinsRemovingCount.value = 0;
  }, 800);
}
watch(memeCoins, startCoinAnimation);
// }

function formatNumber(value: number): string {
  return value.toFixed(3).replace(/\d\d$/, '').replace(/\.0$/, '');
}
const memeCoinsCount = computed<string>(() => {
  if (memeCoins.value < 1000) {
    return memeCoins.value.toString();
  }
  if (memeCoins.value < 1000000) {
    const value = formatNumber(memeCoins.value / 1000);
    return `${value}K`;
  }

  const value = formatNumber(memeCoins.value / 1000000);
  return `${value}M`;
});

const swipesLimitConst: number = parseInt(import.meta.env.VITE_SWIPES_LIMIT, 10) || 60;
const swipesLimit = computed<number>(() => {
  return userStore.data.swipes <= swipesLimitConst
    ? swipesLimitConst
    : userStore.data.swipes;
});
const swipesPercents = computed<number>(() => {
  return (userStore.data.swipes / swipesLimit.value) * 100;
});
</script>

<style lang="scss" scoped>
.top-menu {
  height: var(--header-height);
}

// Coins add animation {
.coin-icon {
  @apply absolute z-30;

  width: 22px;
  height: 22px;
  background-repeat: no-repeat;
  background-image: image-set(
    url('@/assets/icons/coin--1x.webp') 1x,
    url('@/assets/icons/coin--2x.webp') 2x,
  );
  animation-name: coinsAdd;
  animation-duration: 0.6s;
  animation-timing-function: ease-in;
  animation-fill-mode: forwards;

  &.is-1 { top: 175px; left: 90px; }
  &.is-2 { top: 210px; left: 100px; }
  &.is-3 { top: 240px; left: 130px; }
  &.is-4 { top: 200px; left: 180px; }
  &.is-5 { top: 210px; left: 250px; }
}
@keyframes coinsAdd {
  100% { top: 0; left: 0; }
}
// }

// Coins remove animation {
.coins-remove-animation {
  @apply absolute top-0 left-0 z-30 opacity-100;

  animation-name: coinsRemove;
  animation-duration: 0.6s;
  animation-timing-function: ease-in;
  animation-fill-mode: forwards;
}
@keyframes coinsRemove {
  80% { top: 30px; opacity: 0.8; }
  100% { top: 30px; opacity: 0; }
}
// }

.swipes-balance {
  &__value {
    @apply
      flex-centered gap-1 w-16 h-full
      bg-smGray2 bg-gradient-to-r from-smBlue to-smBlueLight3 bg-no-repeat rounded-lg
      text-sm font-semibold text-white;
  }
}
</style>
