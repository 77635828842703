// external
import { ref, computed } from 'vue';
import { defineStore } from 'pinia';
import { useI18n } from 'vue-i18n';
import TgWebApp from '@twa-dev/sdk';
import Cookie from 'js-cookie';
import camelCaseKeys from 'camelcase-keys';
import type { Ref, ComputedRef } from 'vue';
// internal
import useApi from '@/composables/useApi';
import useNotifications from '@/composables/useNotifications';
import { supportedLocales } from '@/plugins/i18n';
import { CookiesNames, cookiesSetOptions } from '@/const/cookies';
import type { UserData, PseudoAuthRequest } from '@/types';

export interface UseUserStore {
  data: Ref<UserData>,
  setData: (newData: UserData) => void,
  setDataPart: (newData: Partial<UserData>) => void,

  generationLimitReached: ComputedRef<boolean>,

  coinsAnimationInProgress: Ref<boolean>,
  addMemeCoins: (count: number) => void,
  decreaseMemeCoins: (count: number) => void,
  addCredits: (count: number) => void,
  decreaseCredits: (count?: number) => void,

  authToken: Ref<string>,
  refreshToken: Ref<string>,
  setAuthToken: (token: string) => void,
  setRefreshToken: (token: string) => void,

  isLogged: ComputedRef<boolean>,

  init: () => Promise<void>,
  resetUserData: () => void,
}

export const useUserStore = defineStore('user', (): UseUserStore => {
  const { locale } = useI18n();
  const { apiUser, apiErrorCatcher } = useApi();
  const notify = useNotifications();

  // Main Data {
  const dataDefaults: UserData = {
    tgId: 0,
    createdAt: '',
    isBot: false,
    isAdmin: false,
    isPremium: false,
    isOnboardingDone: false,
    referalId: null,
    hasTon: false,
    username: '',
    firstName: null,
    lastName: null,
    languageCode: 'en',
    photoUrl: null,
    generationLimit: 3,
    memeCoins: 0,
    tasksCount: 0,
    generationsCount: 0,
    invitedCount: 0,
    friendsGenerated: 0,
    referalCoins: 0,
    // infl fields
    infl: false,
    referralSpending: 0,
    refInfl: false,
    // boost fields
    boostEnds: null,
    // swipes fields
    swipes: parseInt(import.meta.env.VITE_SWIPES_LIMIT, 10) || 60,
    swipedCount: 0,
    swipedGiftCount: 0,
  };

  const data = ref(structuredClone(dataDefaults));

  function setData(newData: UserData) {
    data.value = newData;
  }
  function setDataPart(newData: Partial<UserData>) {
    data.value = { ...data.value, ...newData };
  }

  const generationLimitReached = computed<boolean>(() => data.value.generationLimit < 1);
  // }

  // increasing meme coins with animation
  const coinsAnimationInProgress = ref<boolean>(false); // for running coins animation just one time
  async function addMemeCoins(count: number) {
    const finalCount: number = data.value.memeCoins + count;
    const inc: number = count < 1000 ? 5 : 50;

    while(data.value.memeCoins < finalCount) {
      data.value.memeCoins += inc;
      if (data.value.memeCoins > finalCount) {
        data.value.memeCoins = finalCount;
      }
      await new Promise((resolve) => setTimeout(resolve, 1));
      coinsAnimationInProgress.value = true;
    }

    coinsAnimationInProgress.value = false;
  }
  function decreaseMemeCoins(count: number) {
    const newMemeCoins: number = data.value.memeCoins - count;
    data.value.memeCoins = newMemeCoins > 0 ? newMemeCoins : 0;
  }
  function addCredits(count: number) {
    data.value.generationLimit += count;
  }
  function decreaseCredits(count: number = 1) {
    data.value.generationLimit -= count;

    if (data.value.generationLimit < 0) {
      data.value.generationLimit = 0;
    }
  }

  // Auth Tokens {
  const authToken = ref<string>('');
  const refreshToken = ref<string>('');

  function setAuthToken(token: string): void {
    authToken.value = token;
    if (token.length > 0) {
      Cookie.set(CookiesNames.AUTH_TOKEN, token, cookiesSetOptions);
    } else {
      Cookie.remove(CookiesNames.AUTH_TOKEN);
    }
  }
  function setRefreshToken(token: string): void {
    refreshToken.value = token;
    if (token.length > 0) {
      Cookie.set(CookiesNames.REFRESH_TOKEN, token, cookiesSetOptions);
    } else {
      Cookie.remove(CookiesNames.REFRESH_TOKEN);
    }
  }
  // }

  function resetUserData(): void {
    setData(structuredClone(dataDefaults));
    setAuthToken('');
    setRefreshToken('');
  }

  const isLogged = computed<boolean>(() => !!authToken.value);

  // Init {
  async function tryAuth() {
    try {
      // @ts-ignore
      const query: PseudoAuthRequest = camelCaseKeys(TgWebApp.initDataUnsafe, { deep: true });
      const { data } = await apiUser.pseudoAuth(query);

      if (!data.jwtToken || !data.refreshToken) {
        throw new Error('errors.wrong_response_from_server');
      }

      setAuthToken(data.jwtToken);
      setRefreshToken(data.refreshToken);
    } catch (e) {
      apiErrorCatcher(e);
    }
  }
  async function loadUserData() {
    try {
      const { data } = await apiUser.getUserData();

      if (!data.tgId) {
        throw new TypeError('errors.wrong_response_from_server');
      }

      setData(data);

      if (locale.value !== data.languageCode && supportedLocales.includes(data.languageCode)) {
        locale.value = data.languageCode;
      }
    } catch (e) {
      notify({ text: 'errors.login_error_reload_page' });
    }
  }
  async function init() {
    const savedAuthToken: string = Cookie.get(CookiesNames.AUTH_TOKEN) ?? '';
    const savedRefreshToken: string = Cookie.get(CookiesNames.REFRESH_TOKEN) ?? '';

    if (
      Object.keys(TgWebApp.initDataUnsafe).length > 0 &&
      TgWebApp.initDataUnsafe?.user?.id
    ) {
      await tryAuth();
      if (isLogged.value) {
        await loadUserData();
      }
    } else if (savedAuthToken && savedRefreshToken) {
      authToken.value = savedAuthToken;
      refreshToken.value = savedRefreshToken;
      await loadUserData();
    }

    if (window.ym && data.value.tgId) {
      window.ym(98528358, 'reachGoal', 'uniq', { tgId: data.value.tgId });
    }
  }
  // }

  return {
    data,
    setData,
    setDataPart,

    generationLimitReached,

    coinsAnimationInProgress,
    addMemeCoins,
    decreaseMemeCoins,
    addCredits,
    decreaseCredits,

    authToken,
    refreshToken,
    setAuthToken,
    setRefreshToken,

    isLogged,

    init,
    resetUserData,
  };
});

export default useUserStore;
