// internal
import AppLayout from '@/layouts/default/AppLayout.vue';
import appRoutes from '@/router/appRoutes';

export default [
  {
    path: appRoutes.ROOT,
    name: 'Root',
    component: AppLayout,
    children: [
      {
        path: '',
        name: 'Main',
        redirect: appRoutes.MAIN_PAGE,
      },
      {
        path: appRoutes.MAIN_PAGE,
        name: 'MainPage',
        component: () => import('@/views/MainPage.vue'),
      },
      {
        path: appRoutes.INVITE,
        name: 'Invite',
        component: () => import('@/views/InvitePage.vue'),
      },
      {
        path: appRoutes.RATING,
        name: 'Rating',
        component: () => import('@/views/RatingPage.vue'),
      },
      {
        path: appRoutes.QUESTS,
        name: 'Quests',
        component: () => import('@/views/QuestsPage.vue'),
      },
      {
        path: appRoutes.AIRDROP,
        name: 'Airdrop',
        component: () => import('@/views/AirdropPage.vue'),
      },
      {
        path: appRoutes.PROFILE,
        name: 'Profile',
        component: () => import('@/views/ProfilePage.vue'),
      },
      {
        path: appRoutes.STICKER_PACKS,
        name: 'StickerPacks',
        component: () => import('@/views/StickerPacksPage.vue'),
      },
    ],
  },
  {
    path: appRoutes.ONBOARDING,
    name: 'Onboarding',
    component: () => import('@/views/OnboardingPage.vue'),
  },
  {
    path: appRoutes.NOT_SUPPORTED_PLATFORM,
    name: 'NotSupportedPlatform',
    component: () => import('@/views/NotSupportedPlatformPage.vue'),
  },
];
